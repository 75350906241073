import { Close } from "@mui/icons-material";
import { Fragment } from "react";
import Image from "./Image";

export const ShowVideoAndImage = ({
  media,
  type,
  removeImage,
}: {
  media: string[] | any;
  type: string;
  removeImage: (i: number | string | any) => void;
}) => {
  return (
    <>
      {type?.toLowerCase() === "image" && media.length > 0 ? (
        <div className="w-full flex items-center overflow-x-auto py-2 h-40">
          {media?.map((_v: string, i: React.Key) => {
            return (
              <div className="p-2 relative" key={i}>
                <div
                  className="px-1 py-1 rounded-full text-red-600 w-fit bg-transparent cursor-pointer"
                  onClick={() => removeImage(i)}
                >
                  <Close fontSize="small" />
                </div>
                {_v && (
                  <Image
                    image={_v || ""}
                    width={200}
                    height={200}
                    styles="h-24 bg-gray-300 object-cover w-full "
                  />
                )}
              </div>
            );
          })}
        </div>
      ) : null}
      {type.toLowerCase() === "video" &&
      media.length > 0 &&
      media.length === 1 ? (
        <div className="flex items-center overflow-x-auto py-2 h-full">
          {media?.map((_v: string, i: React.Key | any) => {
            return (
              <div key={i}>
                <div
                  className="px-1 py-1 rounded-full text-red-600 w-fit bg-transparent cursor-pointer"
                  onClick={() => removeImage(i)}
                >
                  <Close fontSize="small" />
                </div>
                <video width="100%" src={_v} controls></video>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};
